
export const Our_Clients = (props) => {
  return (
    <div id='our_clients' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2> Our Premium Clients</h2>
          <img src="./img/premium_clients.png" className="img-responsive" alt="" />{" "}
        </div>
      </div>
    </div>
  )
}
